import React, { useEffect, useState, createRef } from "react";
import {
    makeStyles,
    Button,
    Box
} from "@material-ui/core";
import { TopBar } from "../../topbar/Topbar";
import Navbar from "../../navbar/Navbar";
import axios from '../../../axios-usercharges';
import CountryDateSelection from './CountryDateSelection';
import UpdatedPsuedoInvoice from "./UpdatedPsuedoInvoice";
import { exportCSVFile } from "../../../shared/csv";
import { generatePDF } from "../../../shared/pdf";
import { getHoursMinutesSecondsString } from "../../../shared/util";
import { DateTime } from "luxon";
import { CircularProgress } from '@material-ui/core';
import { getFormattedDateFromMonthYear } from '../../../shared/util';
import BankingDetails from "./BankingDetails"
import Personaldetails from "./PersonalDetails"
import BankingDetailsWithoutData from "./BankingDetailsWithoutData";
import PersonalDetailsWithoutData from "./PersonalDetailsWithoutData"

const formatDateFromISO = (date) => {
    const stringDateSplit = date.split('T')[0].split('-');
    return `${stringDateSplit[2]}/${stringDateSplit[1]}/${stringDateSplit[0]}`
};


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 0px',
        flexGrow: 1
    },
    leftPanel: {
        borderRight: "1px solid #ececec",
    },
    gridContainer: {
        minHeight: '100vh',
        width: '100%'
    },
    controlSection: {
        padding: "5px",
        minHeight: "110px",
        "& h2": {
            padding: "2px",
            fontWeight: 700,
            fontSize: "1rem",
        },
    },
}));


export default function BetaInvoice() {
    
    const classes = useStyles();
    const [data, setData] = useState(null);
     const [data1,setData1] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [country,setCountry] = useState(null)
    const psuedoInvoiceTableRef = createRef();
    const [loading, setLoading] = useState(false);

    const onExportPDFClick = () => {
        const pseudoInvoiceHeadersSeq = ['flightNumber', 'departure', 'arrival', 'aircraftModel', 'etd', 'mtow', 'totalFirCalculatedInfo.distance_nm', 'totalFirCalculatedInfo.cost'];
        // (node, title, data, keys, currency, amount, filename, afterTableCreatedCallback, formatters)
        const title = 'MONTHLY BETA INVOICE';
        const flights = selectedData.data;
        const currency = flights[0].totalFirCalculatedInfo.currency;
        const charge = flights.reduce((acc, currFlight) => {
            return acc + currFlight.totalFirCalculatedInfo.cost
        }, 0);

        generatePDF(
            psuedoInvoiceTableRef.current,
            title,
            flights,
            pseudoInvoiceHeadersSeq,
            currency,
            charge.toFixed(2),
            // TODO: this box in the pdf is limited in size, need to fix for longer title
            'MONTHLY BETA INVOICE.pdf',
            null,
            {
                // TODO: better formatting for numbers
                'mtow': (value) => value.MAX_TOW_KG.toLocaleString('en'),
                'totalFirCalculatedInfo.distance_nm': (value) => value.toFixed(2),
                'totalFirCalculatedInfo.cost': (value) => value.toFixed(2),
                'etd': (value) => formatDateFromISO(value),
            }
    );
    };

    const onExportExcelClick = () => {
        const { country, date, data } = selectedData;
        const pseudoInvoiceHeaders = {
            'flightNumber': 'FLIGHT',
            'departure': 'ORIGIN',
            'arrival': 'DESTINATION',
            'aircraftModel': 'AIRCRAFT',
            'etd': 'DATE',
            'mtow': 'WEIGHT (kg)',
            'totalFirCalculatedInfo.distance_nm': 'DISTANCE (nm)',
            // 'totalFirCalculatedInfo.elapsed_time': 'SEG Time',
            'totalFirCalculatedInfo.cost': 'CHARGE'
        };
        const totalTime = data.reduce((acc, currFlight) => {
            return acc + currFlight.totalFirCalculatedInfo.elapsed_time
        }, 0);
        const totalDistance = data.reduce((acc, currFlight) => {
            return acc + currFlight.totalFirCalculatedInfo.distance_nm
        }, 0);
        const charge = data.reduce((acc, currFlight) => {
            return acc + currFlight.totalFirCalculatedInfo.cost
        }, 0);

        const formattedData = data.map(flight => {
            const totalFirCalculatedInfo = {
                ...flight.totalFirCalculatedInfo,
                cost: flight.totalFirCalculatedInfo.cost.toFixed(2),
                distance_nm: flight.totalFirCalculatedInfo.distance_nm.toFixed(2),
                elapsed_time: getHoursMinutesSecondsString(flight.totalFirCalculatedInfo.elapsed_time),
            };

            return {
                ...flight,
                totalFirCalculatedInfo,
                etd: formatDateFromISO(flight.etd),
                mtow: flight.mtow.MAX_TOW_KG,
            };
        });

        const currency = formattedData[0].totalFirCalculatedInfo.currency;
        const headers = pseudoInvoiceHeaders;
        let preHeader = null;//[country, '', date, `Flights ${data.length}`, `Currency ${currency}`, `Charge ${charge}`, `Total Distance`, `${totalDistance} NM`, "Total Time", `${Math.round(totalTime / 60) % 60} MINS`]
        const fileTitle = `Beta Invoice - ${country} - ${date}`;
        exportCSVFile(headers, formattedData, fileTitle, preHeader);
    };
    useEffect(() => {
        if (localStorage.getItem("BetaInvoice")) {
            const betaInvoiceData = JSON.parse(localStorage.getItem("BetaInvoice"));
            const countriesYearMonth = betaInvoiceData.reduce((acc, curr) => {
                const year = `${curr.etd_year}`;
                const month = curr.etd_month;
                const country = curr.country;
                if (!acc[country]) {
                    acc[country] = {}
                }
                if (!acc[country][year]) {
                    acc[country][year] = [];
                }
                acc[country][year].push(month);
                return acc;
            }, {});
                  
            setData(countriesYearMonth);
        } else {
            axios.get(`/api/betaInvoice`)
            .then((res) => {
         
            const countriesYearMonth = res.data.countriesByYearMonth.reduce((acc, curr) => {
           
                    const year = `${curr.etd_year}`;
                     const month = curr.etd_month;
                    const country = curr.country;
                
                   if (!acc[country]) {
                    acc[country] = {}
                   }
                   // // // check if the country entry exists in the current entry
                   if (!acc[country][year]) {
                       acc[country][year] = [];
                   }
                      acc[country][year].push(month);
                    return acc;
                   }, {});
                  
                setData(countriesYearMonth);
                localStorage.setItem("BetaInvoice", JSON.stringify(res.data.countriesByYearMonth))
                 
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, []);

   

    const flightData1 = (selectedCountry, selectedYearMonth) => { 
        setLoading(true);
        const [year,month] = selectedYearMonth.split(" ");
      setCountry(selectedCountry)
            axios.get(`/api/betaInvoice1/${selectedCountry}/${month}/${year}`)
                .then((res) => {
                //  setData1(res.data)
                res.data.sort(function(a, b){return a - b});
                  const newData =  res.data.map(flight => {
                     
                        const totalFirCalculatedInfo = flight.firCalculatedInfo.filter(fInfo => fInfo.country === selectedCountry)
                        .reduce((acc, currFirInfo) => {
                             
                            // let calcElapsedTime = acc.elapsed_time + currFirInfo.elapsed_time;
                         return {
                                ...acc,
                                cost: acc.cost + currFirInfo.cost,
                                elapsed_time:  acc.elapsed_time + currFirInfo.elapsed_time,
                                distance_nm: acc.distance_nm + currFirInfo.distance_nm,
        
                            }
                        });
                   
                    return { ...flight, totalFirCalculatedInfo }
                
                    });
                    const selectedData = {
                        data: newData,
                        country: selectedCountry,
                         date: getFormattedDateFromMonthYear(selectedYearMonth),
                    };
                    setSelectedData(selectedData);
                    setLoading(false);
                    // console.log(selectedCountry)
               
                 })
                .catch(err => {
                    console.log(err);
                });
                return data1;
       }
  
console.log(country)

    return (
        <div>
            <Box
                className={classes.gridContainer}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="stretch"
            >
            
                <Navbar index={3} />
             
                {!data ? <div><CircularProgress size={70}
        left={-20}
        top={10}
        style={{marginLeft: '50%',marginTop:'40px'}} /> <span style={{fontSize:"2rem"}}>Loading</span></div>: <CountryDateSelection countriesByYearMonth={data} flightData={data1} setSelectedData={setSelectedData}  getFlightData = {flightData1}/>}
           {country==='Jamaica'&& selectedData ?  <BankingDetails /> :selectedData && <BankingDetailsWithoutData />} 
           
         {country==='Jamaica'&& selectedData ?  <Personaldetails/>: selectedData && <PersonalDetailsWithoutData />}
                {selectedData && <Box style={{marginTop:'40px'}}
                    className={classes.actionsContainer}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >  
                    {/* <Button variant="contained" color="secondary" onClick={onResetClick}>
                            Reset
                        </Button> */}
                       
                    <Button variant="contained" color="secondary" style={{width:"130px"}} onClick={onExportPDFClick}>EXPORT PDF</Button>
                    <Button variant="contained" color="secondary" style={{width:"130px"}} onClick={onExportExcelClick}>EXPORT EXCEL</Button>
               
                   
                    
                </Box>}

                {
                    loading ?
                        <div>
                            <CircularProgress
                                size={70}
                                left={-20}
                                top={10}
                                style={{ marginLeft: '50%', marginTop: '40px' }}
                            />
                            <span style={{ fontSize: "2rem" }}>Loading</span>
                        </div> : null}
               
                {selectedData && <UpdatedPsuedoInvoice ref={psuedoInvoiceTableRef} selectedData={selectedData}></UpdatedPsuedoInvoice>}
            </Box>
        </div>
    );
};