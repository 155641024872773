import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import "./BankingDetails.css"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#FFFAFA',
    border:'0.1em solid #42647F',
    position:'absolute',
    borderRadius: '30px',
    left:'75%',
    marginTop:'85px',
    boxShadow: '8px 8px grey'
    // borderRight: '0.1em solid black'
  },
  ListItemText:{
    color:'red'
  }
  // tableRightBorder :{

  //  display: 'inline-block',
  // width: '2px',
  // backgroundColor: 'black',
  // margin: '0 10px',
  // height: '1em'
  // }
}));

export default function ListDividers() {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.root} aria-label="mailbox folders">
        {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
      <ListItem style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText style={{color:'#325C74'}} primary={<Typography style={{fontSize:'1rem'}}><strong>ANS Name:</strong> Jamaica Civil Aviation Authority</Typography>}/>
      </ListItem>
      <Divider />
      <ListItem divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Bank Acocunt#:</strong> 7713-101443-005</Typography>}  />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Bank Name:</strong> New Kingston Branch 1-7 Knutsford Boulevar Kinbgyson 5, Jamaica, West Indes</Typography>} />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Swift Code:</strong> JAMBMVMV</Typography>} />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Correspondent Bank:</strong> Jp Morgan Chase Bank</Typography>} />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>IBAN:</strong> ABA 0002</Typography>} />
      </ListItem>
      <Divider light />
      <ListItem style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Swift Code:</strong> CHASUS33</Typography>} />
      </ListItem> 
    </List>
   
  );
}



{/* <h4>Banking Details</h4>
          <h5>ANS Name: Jamaica Civil Aviation Authority</h5>
          <h5>Bank Acocunt# : 7713-101443-005</h5>
          <h5>Bank Name: New Kingston Branch 1-7 Knutsford Boulevar Kinbgyson 5, Jamaica, West Indes</h5>
          <h5>Swift Code: JAMBMVMV</h5>
          <h5>Correspondent Bank: Jp Morgan Chase Bank</h5>
          <h5>IBAN: ABA 0002</h5>
          <h5>Swift Code: CHASUS33</h5> */}