import React, { useState } from 'react';
import {KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export default function FlightDayPicker({ selectedDate, handleDateChange, useAllDates, setUseAllDates }) {
    // data started being persisted on this day and calculations started
    // const startDate = DateTime.fromISO('2021-01-14');
    // date for JBU demo
    const startDate = DateTime.fromISO('2021-01-15').minus({day: 1});
    return (
        <>
             <KeyboardDatePicker style={{width:'200px',fontSize:'1.25rem'}}
        autoOk
        value={selectedDate}
        placeholder="01/01/2021"
        onChange={date => handleDateChange(date)}
        minDate={startDate}
        maxDate={DateTime.utc()}
        format="DD"
      />

            {/* <DatePicker
                inputVariant="outlined"
                autoOk
                value={selectedDate}
                placeholder="01/01/2021"
                onChange={date => handleDateChange(date)}
                format="DD"
                minDate={startDate}
                maxDate={DateTime.utc()}
            /> */}
            <Typography component="div" variant="h5" >
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                        
                    </Grid>
                </Grid>
            </Typography>
        </>
    );
};


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);
