import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import ReactMapGL, {
  NavigationControl,
  Source,
  Layer,
  WebMercatorViewport,
  FlyToInterpolator,
} from "react-map-gl";
import bbox from "@turf/bbox";
import { makeStyles } from "@material-ui/core";
import { addLogo } from "../../../../../../shared/calculationPdf";

const dataLayer = {
  id: "data",
  type: "line",
  paint: {
    "line-opacity": 1,
    "line-color": ["get", "stroke"],
    "line-width": 3,
  },
};

const labelLayer = {
  id: "poi-labels",
  type: "symbol",
  layout: {
    "symbol-placement": "line-center",
    // this field was changed from ["concat", "$", ["get", "cost"]]
    "text-field": ["concat", ["get", "dist_km"], "km"],
    "text-anchor": "bottom",
    // 'text-font': ['Arial', 'Open Sans Regular'],
    "text-radial-offset": 0,
    "text-justify": "auto",
    "text-size": 15,
    visibility: "visible",
  },
  paint: {
    "text-color": ["get", "stroke"],
    "text-halo-width": 0.7,
    "text-halo-color": "#000",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '10px'
  },
  hide: {
    position: 'absolute',
    left: '-9999px'
  }
}));

const Map = (props, ref) => {
  const classes = useStyles();
  const [viewport, setViewport] = useState({
    width: props.width - 10,
    height: props.height,
    latitude: 0,
    longitude: 0,
    zoom: 5,
  });
  const mapRef = useRef(null);
  const rootClasses = props.hide ? [classes.root, classes.hide] : [classes.root];
  const changeViewportByFeatures = (featuresCollection) => {
    // calculate the bounding box of the feature
    const [minLng, minLat, maxLng, maxLat] = bbox(featuresCollection);
    // construct a viewport instance from the current state
    const webMeractorViewport = new WebMercatorViewport(viewport);
    const { longitude, latitude, zoom } = webMeractorViewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 40,
      }
    );
    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
      transitionDuration: 1000,
    });
  };

  useImperativeHandle(ref, () => ({

    fitBounds(features) {
      changeViewportByFeatures(features);
    },
    addMapToPDF(doc, title, xPos, yPos, width, margin, PDF_MAX_HEIGHT, dataUrl) {
      const MAP_HEIGHT = 100;
      const MAP_WIDTH = 200;
      if (yPos + MAP_HEIGHT + 15 > PDF_MAX_HEIGHT) {
        doc.addPage();
        addLogo(doc, dataUrl, title);
        yPos = margin.top + 15;
      }
      doc.addImage(mapRef.current.getMap().getCanvas().toDataURL('image/png', 1), 'PNG', 5, yPos, MAP_WIDTH, MAP_HEIGHT, null, 'FAST');

    }
  }));
  useEffect(() => {
    if (props.geojson && props.width > 0) {
      changeViewportByFeatures(props.geojson);
    }
  }, [props.geojson, props.width]);

  return (
    <div className={rootClasses.join(' ')}>
      <ReactMapGL
        {...viewport}
        mapStyle="mapbox://styles/urmjs/cka49y5pt16n71io7t5kh9fpx"
        mapboxApiAccessToken={
          "pk.eyJ1IjoidXJtanMiLCJhIjoiY2thNGFrNm93MDcyOTNlbWJxczJ6Ym96aCJ9.cFm84e8mjb9O376GdeS5uQ"
        }
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        attributionControl={false}
        ref={mapRef}
        preserveDrawingBuffer
      >
        <Source type="geojson" data={props.geojson}>
          <Layer {...dataLayer} />
          <Layer {...labelLayer} />
        </Source>
        <div style={{ position: "absolute", top: 5, right: 10 }}>
          <NavigationControl />
        </div>
      </ReactMapGL>
    </div>
  );
};

export default React.forwardRef(Map);
