// @flow
import React, { useRef, useImperativeHandle } from "react";

import Map from "./Map/Map";
import useResize from "../../../../../hooks/useCalculationResize";
import GreatCircleTable from "./GreatCircleTable/GreatCircleTable";
import ClimbCruiseDescentLineChart from "./ClimbCruiseDescentLineChart/ClimbCruiseDescentLineChart";
import FuelPieChart from "./PieChart/PieChart";
import { addLogo, generatePDF } from "../../../../../shared/calculationPdf";
import { exportCSVFile } from "../../../../../shared/csv";
import html2canvas from 'html2canvas';


const GreatCircle = (props, ref) => {
  const componentRef = useRef();
  const hiddenMapRef = useRef();
  const mapRef = useRef();
  const ClimbCruiseDescentLineChartRef = useRef();
  const FuelPieChartRef = useRef();

  const tableRef = useRef();
  const { width } = useResize(componentRef);

  const headers = {
    'properties.fir': 'FIR Code',
    'properties.fir_name': 'FIR Name',
    'properties.country': 'FIR Country',
    'properties.dist_km': 'SEG DIST (km)',
    'properties.dist_nm': 'SEG DIST (nm)',
    'properties.fuel': 'SEG FUEL (kg)',
    'properties.CO2': 'SEG CO2 (kg)',
    'properties.cruiseTime': 'SEG TIME (min)'
  };

  const zoomToRoute = (row) => {
    mapRef.current.fitBounds(row);
  };

  const generateGCPDF = () => {
    if (props.gcData) {
      let totalAmout = 0;
      const data = props.gcData.features.map((gc) => {
        totalAmout += +gc.properties.cost;
        return { ...gc.properties, currency: 'USD' };
      });
      generatePDF(
        tableRef.current,
        'ATC Great Circle',
        data,
        ['stroke', 'fir', 'fir_name', 'country', 'dist_km', 'dist_nm', 'fuel', 'CO2', 'cruiseTime'],
        'USD',
        totalAmout.toFixed(2),
        'ATC Great Circle.pdf',
        [_addChartToPDF, hiddenMapRef.current.addMapToPDF]
      );
    } else if (props.rsData) {
      let totalAmout = 0;
      const data = props.rsData.features.map((gc) => {
        totalAmout += +gc.properties.cost;
        return { ...gc.properties, currency: 'USD' };
      });
      generatePDF(
        tableRef.current,
        'ATC Great Circle',
        data,
        ['stroke', 'fir', 'fir_name', 'country', 'dist_km', 'dist_nm', 'fuel', 'CO2', 'cruiseTime'],
        'USD',
        totalAmout.toFixed(2),
        'ATC Great Circle.pdf',
        [_addChartToPDF, hiddenMapRef.current.addMapToPDF]
      );

    }
  };

  // addMapToPDF(doc, title, xPos, yPos, width, margin, PDF_MAX_HEIGHT, dataUrl) {
  //   const MAP_HEIGHT = 100;
  //   const MAP_WIDTH = 200;
  //   if (yPos + MAP_HEIGHT + 15 > PDF_MAX_HEIGHT) {
  //     doc.addPage();
  //     addLogo(doc, dataUrl, title);
  //     yPos = margin.top + 15;
  //   }
  //   doc.addImage(mapRef.current.getMap().getCanvas().toDataURL('image/png', 1), 'PNG', 5, yPos, MAP_WIDTH, MAP_HEIGHT, null, 'FAST');
  //   doc.save("ATC Flight Charge Summary.pdf");
  // }

  const _addChartToPDF = async (doc, title, xPos, yPos, width, margin, maxHeight, dataUrl) => {
    const CHART_HEIGHT = 70;
    const CHART_MARGIN_TOP = 10;
    const CHART_WIDTH = 110;

    if (yPos + CHART_HEIGHT > maxHeight) {
      doc.addPage();
      addLogo(doc, dataUrl, title);
      yPos = margin.top + 10;
    }
    const canvas = await html2canvas(ClimbCruiseDescentLineChartRef.current, { backgroundColor: '#fff', scrollY: -window.scrollY });
    let imageData = canvas.toDataURL('image/png', 1);
    doc.addImage(imageData, 'PNG', margin.left, yPos + CHART_MARGIN_TOP, CHART_WIDTH, CHART_HEIGHT);
    const canvas2 = await html2canvas(FuelPieChartRef.current, { backgroundColor: '#fff', scrollY: -window.scrollY });
    let imageData2 = canvas2.toDataURL('image/png', 1);
    doc.addImage(imageData2, 'PNG', margin.left + CHART_WIDTH - 10, yPos + CHART_MARGIN_TOP, CHART_WIDTH, CHART_HEIGHT);
    doc.save("ATC Great Circle.pdf");

  };

  useImperativeHandle(ref, () => ({
    generateGreatCiclePDF() {
      generateGCPDF();
    },
    generateGCExel() {
      if (props.gcData) {
        let data, fileTitle;
        data = [...props.gcData.features];
        fileTitle = 'Great Circle';
        exportCSVFile(headers, data, fileTitle);
      } else if (props.rsData) {
        let data, fileTitle;
        data = [...props.rsData.features];
        fileTitle = 'Route String';
        exportCSVFile(headers, data, fileTitle);
      }
    }
  }));
  let content = "";
  if (props.gcData) {
    content = (
      <React.Fragment>
        <div style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '1fr 2fr', gridGap: '20px' }}>
          <div style={{ gridRow: '1 / 2', gridColumn: '2 / 3', marginTop: '20px' }}>
            <Map geojson={props.gcData} width={1200} height={500} ref={mapRef} />
            <Map geojson={props.gcData} width={1200} height={600} ref={hiddenMapRef} hide={true} />
            <div style={{ display: 'flex' }}>
              <FuelPieChart gcData={props.gcData} ref={FuelPieChartRef} />
              <ClimbCruiseDescentLineChart gcData={props.gcData} ref={ClimbCruiseDescentLineChartRef} />
            </div>

          </div>

          <div style={{ gridRow: '1 / 2', gridColumn: '1 / 2', marginTop: '10px', paddingRight: '20px' }}>
            <GreatCircleTable class="greatCircleTable" features={props.gcData.features} climbData={props.gcData.climbData} descentData={props.gcData.descentData} zoomToRoute={zoomToRoute} ref={tableRef} style={{ margin: 0 }} />
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props.rsData) {
    content = (
      <React.Fragment>
        <div style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '1fr 2fr', gridGap: '20px' }}>
          <div style={{ gridRow: '1 / 2', gridColumn: '2 / 3', marginTop: '20px' }}>
            <Map geojson={props.rsData} width={1200} height={500} ref={mapRef} />
            <Map geojson={props.rsData} width={1200} height={600} ref={hiddenMapRef} hide={true} />
            <div style={{ display: 'flex' }}>
              <FuelPieChart gcData={props.rsData} ref={FuelPieChartRef} />
              <ClimbCruiseDescentLineChart gcData={props.rsData} ref={ClimbCruiseDescentLineChartRef} />
            </div>
          </div>
          <div style={{ gridRow: '1 / 2', gridColumn: '1 / 2', marginTop: '10px', paddingRight: '20px' }}>
            <GreatCircleTable class="greatCircleTable" features={props.rsData.features} climbData={props.rsData?.climbData} descentData={props.rsData?.descentData} zoomToRoute={zoomToRoute} ref={tableRef} style={{ margin: 0 }} />
          </div>
        </div>
      </React.Fragment>
    );
  }
  return <div ref={componentRef}>{content}</div>;
};

export default React.forwardRef(GreatCircle);
