import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import DetailedFlightInfoTable from './DetailedFlightInfoTable';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 0),
    },
}));

export default function FirModal({ firInfo, setModalOpen }) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const detailedFirBody = (
        <div style={{"top": "50%", "left": "50%", "transform": "translate(-50%, -50%)"}} className={classes.paper}>
               <Button color="secondary" style={{position: "absolute",
    color: "red",
    top: "-15px",
    right: "-17px",
    fontSize:"1.5rem"}} onClick={() => setModalOpen(false)}>X</Button>
    <h3 style={{textAlign:"center",fontSize:"1.25rem"}}>FIR DRILL DOWN</h3>
            <DetailedFlightInfoTable detailedFirInfo={firInfo} />
        </div>
    );

    return (
        <div>
            <Modal
                open={true}
             
            >
                {detailedFirBody}
            </Modal>
        </div>
    );
}