import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, Box, Button, withStyles, Paper } from '@material-ui/core';
import { DateTime } from 'luxon';
import { formatDateFromISO } from '../../shared/util';
import NumberFormat from 'react-number-format';
import FlightRouteTooltipCell from './FlightRouteTooltipCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import "./FlighInfoBanner.css";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#a0a5a8',
        fontWeight: 'bold',
        border: '1px solid black',
        marginTop:"50px",
       
    },
    defaultCell: {
        border: '1px solid black',
        fontWeight: 'bold',
    },
    blueCell: {
        border: '1px solid black',
        fontWeight: 'bold',
        backgroundColor: '#B8DBEF',
    },
    buttonCell: {
        border: '1px solid white',
        backgroundColor: 'white'
    },
    routeCell: {
        border: '1px solid black',
        backgroundColor: '#b9c93a',
        fontWeight: 'bold',
        maxWidth: '10rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dateCell: {
        backgroundColor: '#93e6a5',
        border: '1px solid black',
        fontWeight: 'bold',
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 16,
        borderBottomColor: theme.palette.common.black,
    },
    body: {
        fontSize: '1rem',
    },

}))(TableCell);


export default function FlightInfoBanner({ selectedFlightInfo, GreatCircleRef }) {
    const classes = useStyles();
    const { flightRef, tailNumber, flightNumber, aircraftModel, arrival, departure, eta, etd, atd, ata, mtow, atcCosts, totalCosts, legacy_format } = selectedFlightInfo;
    

    // TODO: when functionality is completed for user selecting weight type, it will be pass in as a prop and can access correct property
    const [weightType,setWeightType] = useState("LB")
    const weight = mtow ? mtow[`MAX_TOW_${weightType}`] : "";

    const handlePdfExportClick = () => {
        if (GreatCircleRef && GreatCircleRef.current) {
            GreatCircleRef.current.generateGreatCiclePDF();
        }
    };

    const handleExcelExportClick = () => {
        if (GreatCircleRef && GreatCircleRef.current) {
            GreatCircleRef.current.generateGCExel();
        }
    };

    return (
        <div className="oneTable">
        <TableContainer style={{marginLeft:'30px'}} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">FLIGHT</StyledTableCell>
                        <StyledTableCell align="center">ACFT</StyledTableCell>
                        <StyledTableCell align="center">ORG</StyledTableCell>
                        <StyledTableCell align="center">DEST</StyledTableCell>
                        <StyledTableCell align="center">ATDz</StyledTableCell>
                        <StyledTableCell align="center">ATAz</StyledTableCell>
                        <StyledTableCell style={{cursor:'pointer'}} onClick={()=>weightType === 'LB'? setWeightType('KG'):setWeightType('LB')}align="center">MTOW {weightType}</StyledTableCell>
                        <StyledTableCell align="center">ATC COST(USD)</StyledTableCell>
                        <StyledTableCell align="center">ROUTE</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">{"FLX"+flightNumber.slice(3)}</StyledTableCell>
                        <StyledTableCell align="center">{aircraftModel}</StyledTableCell>
                        <StyledTableCell align="center">{departure}</StyledTableCell>
                        <StyledTableCell align="center">{arrival}</StyledTableCell>
                        <StyledTableCell align="center">{DateTime.fromISO(etd).toLocaleString(DateTime.DATETIME_MED)}</StyledTableCell>
                        <StyledTableCell align="center">{DateTime.fromISO(eta).toLocaleString(DateTime.DATETIME_MED)}</StyledTableCell>
                        <StyledTableCell align="center">
                            <NumberFormat
                                value={weight}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${weightType}`}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <NumberFormat
                                value={totalCosts}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'$ '}
                            />
                        </StyledTableCell>
                        <FlightRouteTooltipCell route={legacy_format} />
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <div>
        <StyledTableCell>
                            <Button variant="contained" color="secondary"  style={{width:"130px"}} onClick={handlePdfExportClick}>EXPORT PDF</Button>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Button  style={{width:"130px"}} variant="contained" color="secondary" onClick={handleExcelExportClick}>EXPORT EXCEL</Button>
                        </StyledTableCell>
                        </div>
        </div>
    );
};