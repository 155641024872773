import React, { Component } from 'react'
import { TopBar } from "../topbar/Topbar";
import Navbar from '../navbar/Navbar'
import TabPanel from './TabPanel'

export default function Insight() {
    return (
        <div>
            <TopBar />
            <Navbar index={1} />
            <TabPanel />
        </div>
    )
}
