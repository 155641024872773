// @flow 
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Typography, Grid, IconButton} from '@material-ui/core';
//import IATALogo from '../../assets/logo/top_logo.png';
import FliteXLogo from '../../assets/logo/new_flitex_logo.jpg';
import AfraaLogo from '../../assets/logo/AFRAA_logo.png';
import { Link } from 'react-router-dom';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import CurrentDateTime from "../FlightGains/CurrentTimeDate"


const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 300,
    '& .MuiButton-root': {
      fontSize: '12px',
    },
  },
  active: {
    width: "120px",
    color: "white",
    backgroundColor: "#4287f5",
    fontWeight: 700,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "0.9rem",
    padding: "24px",
    textAlign: "center",
  },
  style: {
    width: "120px",
    color: "white",
    textDecoration: "none",
    fontSize: "0.9rem",
    padding: "24px",
    textAlign: "center",

  },
  iconRoot: {
    display: "flex",
    width: '145px',
    height: '94px',
    position: 'relative',
    background: 'white',
    top: '-15px',
    justifyContent: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: '#003487',
    boxSizing: "border-box"
  },
  imgStyle: {
    marginTop: "25px"
  },
  heading: {
    textAlign: "center",
  },
  headingContainer: {
    position: "relative",
  },
  loginDetails: {
    position: "absolute",
    right: '10px',
    top: '5px',
    color:'white',
    fontSize:'1rem'
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const logo = localStorage.account === "afraa" ? AfraaLogo : FliteXLogo;
  const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [userRes, userError, userIsLoading, fetchUser] = useFetch({}, {  
      method: 'get',
      url: '/api/user'
    });
         
    const handleLogoutClick = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('BetaInvoice');
      history.push('/');
    }
    // return (
    //  

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} >
        <Toolbar className={classes.toolbar}>
          <Icon classes={{ root: classes.iconRoot }}>
            <img alt="IATA" src={FliteXLogo} width="80%" height="80%" style={{marginTop:"25px"}}/>
          </Icon>
          {/* <Link to="/" className={props.index === 0 ? classes.active : classes.style}>CALCULATIONS</Link>
          <Link to="/Insights" className={props.index === 1 ? classes.active : classes.style}>INSIGHTS</Link> */}
          <Link to="/" className={props.index === 2 ? classes.active : classes.style}>fliteROUTE</Link>
          <Link to="/betaInvoicing" className={props.index === 3 ? classes.active : classes.style}>betaINVOICING</Link>
          <Link to="/calculations" className={props.index === 4 ? classes.active : classes.style}>Calculations</Link>
          <Typography variant="h4" component="h1" style={{textAlign:'center',marginLeft:'15%',fontFamily: "'Mukta', sans-serif"}}>
          fliteGAINS
        </Typography>
        <div classes={classes.headingContainer}>
        <div className={classes.loginDetails}>
        <Grid container direction="row" alignItems="center">
        <CurrentDateTime />
            <Grid item>
            
              <IconButton size="large" style={{color:'white'}}><AccountCircleIcon /></IconButton>
            </Grid>
            <Grid item>{userRes.username}</Grid>
            <Grid item>
              <Button style={{color:'white',fontSize:'1rem'}} onClick={handleLogoutClick}>Log out</Button>
              </Grid>
              </Grid>
              </div>
           </div>
          
        </Toolbar>
      </AppBar>
    </div>
  );
};
