import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import UpdatedGreatCircle from '../../containers/Calculation/RightPanel/GreatCircle/UpdatedGreatCircle';
import { Button, Dialog } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // paper: {
    //     position: 'absolute',
    //     height: '90vh',
    //     backgroundColor: theme.palette.background.paper,
    //     border: '2px solid #000',
    //     boxShadow: theme.shadows[5],
    //     // padding: theme.spacing(2, 4, 3),
    // },
    paper:{
        minHeight:"80vh",
        maxHeight: '80vh'
    }
}));

export default function FlightMapModal({ selectedFlightInfo,selectedDate, setModalOpen, GreatCircleRef }) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // getModalStyle is not a pure function, we roll the style only on the first render
    const modalBody = (
        <div >
            <h2 style={{textAlign:"center"}}>ROUTE COST ANALYSIS</h2>
             <Button color="secondary" style={{position: "absolute",
    color: "red",
    top: "-10px",
    right: "-10px",
    fontSize:"1.5rem"}} onClick={() => setModalOpen(false)}>X</Button>
            <UpdatedGreatCircle ref={GreatCircleRef}  selectedFlightInfo={selectedFlightInfo} selectedDate={selectedDate}/>
           
        </div>
    );

    return (
        <div>
            <Dialog fullWidth classes={{ paper: classes.paper }}
  maxWidth="xl"
                open={true}>
                {modalBody}
            </Dialog>
           
        </div>
    );
}