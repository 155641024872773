import React, { useState } from 'react';
import { withStyles, makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { Button, Tooltip } from '@material-ui/core';
import { DateTime } from 'luxon';
import { getTime } from '../../shared/util';
import FlightRouteTooltipCell from './FlightRouteTooltipCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "./FlightInfoTable.css";
import { CopyToClipboard } from "react-copy-to-clipboard";



const getFlightInfoPlusDerived = (f) => {
    const atcCost = Number(f.firCalculatedInfo.map(fc => fc.commonUsdCost).reduce((acc, curr) => acc + curr));
    const totalCosts = atcCost;
    return { ...f, atcCost, totalCosts }
};

const isSameDay = (selectedDate, b) => {
    const a = selectedDate;
    b = DateTime.fromISO(b);
    return a.hasSame(b, 'year') && a.hasSame(b, 'day') && a.hasSame(b, 'month');
};

// Need to implement sorting, Route (bolded terms?)
export default function FlightInfoTable({ handleVerify, flightInfo, selectedDate, useAllDates, handleMap }) {

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('totalCosts');
    const [weightType, setWeightType] = useState('LB');
    flightInfo = useAllDates ? flightInfo.map(getFlightInfoPlusDerived) : flightInfo.filter(f => isSameDay(selectedDate, f.etd)).map(getFlightInfoPlusDerived);
  
 
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const descendingComparator = (a, b, orderBy) => {
        let aVal = a[orderBy];
        let bVal = b[orderBy];

        if (orderBy === 'mtow') {
            aVal = a.mtow[`MAX_TOW_${weightType}`];
            bVal = b.mtow[`MAX_TOW_${weightType}`];
        }

        if (bVal < aVal) {
            return -1;
        }
        if (bVal > aVal) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };


    const headers = [
        
        { id: 'totalCosts', headerLabel: 'TOTAL COSTS', align: 'center', sortable: true, valueKey: 'totalCosts', formatFn: (cost) => cost.toFixed(2) },
        // { id: 'verifyButton', headerLabel: '', align: 'center', sortable: false, valueKey: null, formatFn: null }, // could possibly use format to return the button
        { id: 'mapButton', headerLabel: '', align: 'left', sortable: false, valueKey: null, formatFn: null }, // could possibly use format to return the button
        { id: 'flightNumber', headerLabel: 'FLIGHT', align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'tailNumber', headerLabel: 'Tail#', align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'aircraftModel', headerLabel: 'ACFT', align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'std', headerLabel: 'ATDz', align: 'center', sortable: true, formatFn: '' },
        { id: 'departure', headerLabel: 'ORG', align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'arrival', headerLabel: 'DEST', align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'eta', headerLabel: 'ATAz', align: 'center', sortable: true, formatFn: '' },
        // TODO: fix being able to sort by weight (its an object with {MAX_TOW_KG, MAX_TOW_L, valueKey: '', formatFn: ''B})
        { id: 'mtow', headerLabel: 'mtow', align: 'center', sortable: false, valueKey: '', formatFn: '' },
        { id: 'atcCost', headerLabel: `ATC COST(USD)`, align: 'center', sortable: true, valueKey: '', formatFn: '' },
        { id: 'fuelCost', headerLabel: 'FUEL COST', align: 'left', sortable: true, valueKey: '', formatFn: '' },
        { id: 'otherTdvc', headerLabel: 'OTHER TDVC',width:"4px", align: 'left', sortable: true, valueKey: '', formatFn: '' },
        { id: 'route', headerLabel: 'ROUTE', align: 'center', sortable: false, valueKey: '', formatFn: '' },
    ];

    const EnhancedTableHead = (props) => {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headers.map((h) => (
                        <StyledTableCell key={h.id} align={h.align} sortDirection={orderBy === h.id ? order : false}>
                            {
                                (h.sortable ?
                                    (<StyledTableSortLabel hideSortIcon active={orderBy === h.id} direction={orderBy === h.id ? order : 'asc'} onClick={createSortHandler(h.id)}>
                                        {h.headerLabel}
                                        {orderBy === h.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </StyledTableSortLabel>)
                                    : h.headerLabel === 'mtow' ? <span style={{cursor:'pointer'}} onClick={()=>weightType === 'LB'? setWeightType('KG'):setWeightType('LB')}>MTOW<br />{weightType}</span> : <span >{h.headerLabel}</span>
                                )
                            }
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            
        );
    };

    const createFlightNumberCell = (flightNumber, arrivalStatus) => {
        // const { flightNumber, arrivalStatus } = info;
        const backgroundColors = {
            planned: { backgroundColor: '#DAA520', color: 'black' },
            arrived: { backgroundColor: '#016401', color: 'white' },
            departed: { backgroundColor: '#00008B', color: 'white' },
        }
        const { backgroundColor, color } = backgroundColors[arrivalStatus ? arrivalStatus : 'planned'];

        return (
            <StyledTableCell>
                <div style={{ color, backgroundColor, borderRadius: '25px', textAlign: 'center', fontWeight: 'bold', padding: '5px 20px',width:"70px" }}>
                    {flightNumber}
                </div>
            </StyledTableCell>
        )
    };

    
    const createDefaultRow = (flightInfo, index) => {
        // TODO: ensure that it converts to common currency or backend provides tha
        const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
//  console.log(numberWithCommas(flightInfo.mtow[`MAX_TOW_${weightType}`]))

        return (
            <StyledTableRow  key={flightInfo.flight_ref}>
                <StyledTableCell align="center" ><strong>{flightInfo.totalCosts.toFixed(2)}</strong></StyledTableCell>
                {/* <StyledTableCell align="center">
                    <Button style={{ borderRadius: '7px', paddingLeft: '10px', paddingRight: '10px' }} onClick={() => handleVerify(flightInfo)} variant="contained" color="primary">VERIFY</Button>
                </StyledTableCell> */}
                <StyledTableCell align="left"  >
                    <Button style={{ borderRadius: '7px', paddingLeft: '10px', paddingRight: '10px' }}  onClick={() => handleVerify(flightInfo)} variant="contained" color="secondary">TRACK</Button>
                </StyledTableCell>
                <StyledTableCell align="center" >
                <Button style={{ backgroundColor: "Transparent",
                 backgroundRepeat:"no-repeat",
                 border: "none",
                 cursor:"pointer",
                 overflow: "hidden",
                 outline:"none",
                 }} onClick={() => handleMap(flightInfo)} variant="contained">{createFlightNumberCell("FLX"+(flightInfo.flightNumber.slice(3)), flightInfo.arrivalStatus)}</Button>
                </StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.tailNumber ? flightInfo.tailNumber : "N/A"}</StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.aircraftModel}</StyledTableCell>
                <StyledTableCell align="center" >{useAllDates ? flightInfo.std : getTime(flightInfo.std)}</StyledTableCell>
                <StyledTableCell align="center">{flightInfo.departure}</StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.arrival}</StyledTableCell>
                <StyledTableCell align="center" >{useAllDates ? flightInfo.eta : getTime(flightInfo.eta)}</StyledTableCell>
                {/* <StyledTableCell align="center">
               
                    {numberWithCommas(flightInfo.mtow[`MAX_TOW_${weightType}`])}</StyledTableCell>
                <StyledTableCell align="center">$&nbsp;{flightInfo.atcCost.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="center">$&nbsp;{flightInfo.fuelCost}</StyledTableCell>
                <StyledTableCell align="center">$&nbsp;{flightInfo.otherTDVC}</StyledTableCell>
                <FlightRouteTooltipCell route={flightInfo.route} /> */}
                <StyledTableCell align="center" style={{width:"4px"}}>
                    {(numberWithCommas((weightType === "LB" && flightInfo.mtow) ? flightInfo?.mtow.MAX_TOW_LB : flightInfo.mtow ? flightInfo.mtow.MAX_TOW_KG : "0"))}</StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.atcCost.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.fuelCost}</StyledTableCell>
                <StyledTableCell align="center" >{flightInfo.otherTDVC}</StyledTableCell >
                <FlightRouteTooltipCell route={flightInfo.legacy_format} />
            </StyledTableRow>
           
        )
    };

    return (
  
        <Paper className={classes.paper}>
        
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort} />
                    <TableBody>
                        {
                            stableSort(flightInfo, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(createDefaultRow)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={flightInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    
    );
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 16,
        borderBottomColor: theme.palette.common.black,
    },
    body: {
        fontSize: '1rem',
        
    },

}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#E7E7E7",
        },
    },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
    root: {
        "&:hover": {
            color: "#E5176E"
        },
        "&$active": {
            color: theme.palette.common.white,
        },
    },
    active: {},
    icon: {
        color: "#E5176E !important",
    },
}))(TableSortLabel);

const useStyles = makeStyles((theme) => ({
    root: {
    },
    // TODO: possible figure out better css to limit the height
    container: {
    },
    paper:{
      margin:'0 5%' ,
    //  width:"90vw"
     
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));