import * as React from 'react';
import { useState } from 'react';
import ExploreIcon from '@material-ui/icons/Explore';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StyledTableRow from '../../../../../StyledTableRow/StyledTableRow';
import StyledTableCell from '../../../../../StyledTableCell/StyledTableCell';


const useStyles = makeStyles((theme) => ({
  tableContainer: {
    background: theme.palette.common.white,
    margin: '10px 0 10px 0'
  },
  tableHeader: {
    // background: theme.palette.info.dark,
    background: '#DFCE97',
    "& th": {
      // color: theme.palette.common.white,
      color: theme.palette.common.black,
      fontFamily: 'Mukta, sans-serif',
      fontWeight: 'bold',
      fontSize: '1rem',
      border: '1px solid #fff',
    },
  },
  row: {
    background: theme.palette.common.white,
    fontFamily: 'Mukta, sans-serif',
    fontSize: '1rem',
  },
  strokeCell: {
    padding: '0px',
    fontFamily: 'Mukta, sans-serif',
    fontSize: '1rem',
  },
  currencyCell: {
    background: '#1D9EFF',
    color: '#fff'
  },
  amountCell: {
    background: '#0070C0',
    color: '#fff'
  }
}));

// converts number in minutes to hours and minutes hh:mm
const formatTime = (minutes) => {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};


const GreatCircleTable = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const currency = 'USD';
  let totalAmount = 0;
  let totalDistanceKm = 0;
  let totalDistanceNm = 0;
  let totalFuel = 0;
  let totalCO2 = 0;
  let totalTime = 0;
  const [km, setKm] = React.useState(true);

  const toggleDistanceUnit = () => {
    setKm(!km);
  };

  const rows = props.features.map((row, index) => {
    totalDistanceKm += +row.properties.dist_km;
    totalDistanceNm += +row.properties.dist_nm;
    totalFuel += +row.properties.fuel;
    totalCO2 += +row.properties["CO2"];
    totalTime += +row.properties.cruiseTime;
    return (
      <StyledTableRow key={index} className={classes.row}>
        <StyledTableCell align="center" style={{ cursor: 'pointer', fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }} onClick={() => props.zoomToRoute(row)} >
          <IconButton className={classes.strokeCell} style={{ color: row.properties.stroke }} variant="outlined"><ExploreIcon /></IconButton>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{row.properties.fir}</StyledTableCell>
        <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{row.properties.fir_name?.slice(4)}</StyledTableCell>
        <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{row.properties.country}</StyledTableCell>
        {km && <StyledTableCell align="center">
          <NumberFormat
            value={parseInt(row.properties.dist_km).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
          />
        </StyledTableCell>}
        {!km && <StyledTableCell align="center">
          <NumberFormat
            value={parseInt(row.properties.dist_nm).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
          />
        </StyledTableCell>}
        {/* Removing the  table cells we don't need here*/}
        {/* <StyledTableCell align="center">USD</StyledTableCell> */}
        {/* <StyledTableCell align="right">
          <NumberFormat
            value={row.properties.cost || ""}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </StyledTableCell> */}
        {km && <StyledTableCell align="center">
          <NumberFormat
            value={totalDistanceKm.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
          />
        </StyledTableCell>}
        {!km && <StyledTableCell align="center">
          <NumberFormat
            value={totalDistanceNm.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
          />
        </StyledTableCell>}
        <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
          {formatTime(row.properties.cruiseTime)}
        </StyledTableCell>
        <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
          {row.properties.flightType == "P" ? row.properties.flightType : row.properties.flightType == "F" ? row.properties.flightType : "-"} 
        {/*<NumberFormat
            value={parseInt(row.properties.fuel).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
      /> */}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
        {row.properties.flightType === "F" && row.properties.euroControlChargeAmount ? row.properties.euroControlChargeAmount : row.properties.navCanadaChargeAmount ? row.properties.navCanadaChargeAmount : "-"}
        {/* <NumberFormat
          value={(row.properties.flightType === "F" ? row.properties.euroControlChargeAmount : row.properties.navCanadaChargeAmount)}
          displayType={"text"}
          thousandSeparator={true}
          style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
    /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  });
  return (
    <TableContainer component={Box} elevation={1} className={classes.tableContainer}>
      <Table className='HarshSevak' size="small" aria-label="a dense table" ref={ref} >
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="center" style={{ width: '20px' }}>Explore</TableCell>
            <TableCell align="left">FIR Code</TableCell>
            <TableCell align="left">FIR Name</TableCell>
            <TableCell align="left">FIR Country</TableCell>
            {km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >SEG DIST (km) <SwapHoriz /></TableCell>}
            {!km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >SEG DIST (nm) <SwapHoriz /></TableCell>}
            {/* Removing the  table cells we don't need here*/}
            {/* <TableCell align="center">Common-rated Currency</TableCell> */}
            {/* <TableCell align="center">Converted USD$</TableCell> */}
            {km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >CUM DIST (km) <SwapHoriz /></TableCell>}
            {!km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >CUM DIST (nm) <SwapHoriz /></TableCell>}
            <TableCell align="left">SEG TIME (hh:mm)</TableCell>
            <TableCell align="left">Charge Type</TableCell>
            <TableCell align="left">Charge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.row}>
          {rows}
          <TableRow>
            {/* <TableCell colSpan={5}></TableCell> */}
            {/* <TableCell align="center" className={classes.currencyCell}>{currency}</TableCell> */}
            {/* <TableCell align="right" className={classes.amountCell}><NumberFormat value={totalAmount} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} /></TableCell> */}
          </TableRow>
        </TableBody>
      </Table>
      <Table size="small"  >
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left">Phase</TableCell>
            <TableCell align="left">FIR Code</TableCell>
            <TableCell align="left">FIR Name</TableCell>
            {km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >DIST (km) <SwapHoriz /></TableCell>}
            {!km && <TableCell align="center" onClick={toggleDistanceUnit} style={{ cursor: 'pointer' }} >DIST (nm) <SwapHoriz /></TableCell>}
            <TableCell align="left">FUEL (kg)</TableCell>
            <TableCell align="left">CO2 (kg)</TableCell>
            <TableCell align="left">TIME (hh:mm)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.row}>
          <StyledTableRow className={classes.row}>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>Climb</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.climbData?.climbFIR}</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.climbData?.climbFIR_name?.slice(4)}</StyledTableCell>
            {km && <StyledTableCell align="center">
              <NumberFormat
                value={props?.climbData?.climbDistanceKm}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            {!km && <StyledTableCell align="center">
              <NumberFormat
                value={props?.climbData?.climbDistanceNm}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={props?.climbData?.climbFuel}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={props?.climbData?.climbCO2}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              {formatTime(props?.climbData?.climbTime)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow className={classes.row}>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>Cruise</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.descentData?.cruiseFIR}</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.descentData?.cruiseFIR_name?.slice(4)}</StyledTableCell>
            {km && <StyledTableCell align="center">
              <NumberFormat
                value={totalDistanceKm.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            {!km && <StyledTableCell align="center">
              <NumberFormat
                value={totalDistanceNm.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={totalFuel.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={(totalCO2).toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              {formatTime(totalTime)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow className={classes.row}>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>Descent</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.descentData?.descentFIR}</StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>{props?.descentData?.descentFIR_name?.slice(4)}</StyledTableCell>
            {km && <StyledTableCell align="center">
              <NumberFormat
                value={props?.descentData?.descentDistanceKm}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            {!km && <StyledTableCell align="center">
              <NumberFormat
                value={props?.descentData?.descentDistanceNm}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={props?.descentData?.descentFuel}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={props?.descentData?.descentCO2}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              {formatTime(props?.descentData?.descentTime)}</StyledTableCell>
          </StyledTableRow>


          <StyledTableRow className={classes.row}>
            <StyledTableCell align="center" colSpan={3} style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem', fontWeight: 'bold', background: '#DFCE97' }}>Total</StyledTableCell>
            {km && <StyledTableCell align="center">
              <NumberFormat
                value={(parseInt(props?.descentData?.descentDistanceKm) + totalDistanceKm + parseInt(props?.climbData?.climbDistanceKm)).toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            {!km && <StyledTableCell align="center">
              <NumberFormat
                value={(parseInt(props?.descentData?.descentDistanceNm) + totalDistanceNm + parseInt(props?.climbData?.climbDistanceNm)).toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>}
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              <NumberFormat
                value={(parseInt(props?.descentData?.descentFuel) + totalFuel + parseInt(props?.climbData?.climbFuel)).toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}><NumberFormat
              value={((parseInt(props?.descentData?.descentCO2) + totalCO2 + parseInt(props?.climbData?.climbCO2))).toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
              style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}
            />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1rem' }}>
              {formatTime((parseInt(props?.descentData?.descentTime) + totalTime + parseInt(props?.climbData?.climbTime)).toFixed(0))}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});


export default GreatCircleTable;