import * as React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, TablePagination, TableFooter,IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StyledTableRow from '../../../components/StyledTableRow/StyledTableRow';
import StyledTableCell from '../../../components/StyledTableCell/StyledTableCell';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import StyledBlackTableHeader from '../../../components/StyledBlackTableHeader/StyledBlackTableHeader';
import { DateTime } from 'luxon';
import { getHoursMinutesSecondsString } from '../../../shared/util';
import axios from '../../../axios-usercharges';
import FlightMapModal from '../FlightMapModal';
import ExploreIcon from '@material-ui/icons/Explore';


const useStyles = makeStyles((theme) => ({
    tableContainer: {
        background: theme.palette.common.white,
        marginTop:'180px'
    },
    tableHeader: {
        background: theme.palette.info.dark,
        "& th": {
            color: theme.palette.common.white,
            border: '1px solid #fff'
        },
    },
    highlightTableHeader: {
        fontSize: '20px'
    },
    row: {
        background: theme.palette.common.white,
        height: '30px'
    },
    currencyCell: {
        background: '#1D9EFF',
        color: '#fff'
    },
    amountCell: {
        background: '#0070C0',
        color: '#fff'
    }
}));

const UpdatedPsuedoInvoice = React.forwardRef((props, ref) => {
    const classes = useStyles();
     const GreatCircleRef = React.createRef();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { country, data, date } = props.selectedData;
    const [selectedFlightInfo, setSelectedFlightInfo] =  React.useState(null);
    const [selectedDate,setSelectedDate] = React.useState(null);
    // const [flightInfo, setFlightInfo] =  React.useState(null);
    const [mapModalOpen, setMapModalOpen] = React.useState(false);
  
    const currency = data.length > 0 && data[0].totalFirCalculatedInfo && data[0].totalFirCalculatedInfo.currency;
    const numFlights = data.length;
    
    const totalTime = data.reduce((acc, currFlight) => {
        return acc + currFlight.totalFirCalculatedInfo.elapsed_time
    }, 0);
    const totalDistance = data.reduce((acc, currFlight) => {
        return acc + currFlight.totalFirCalculatedInfo.distance_nm
    }, 0);

    const charge = data.reduce((acc, currFlight) => {
         return acc + currFlight.totalFirCalculatedInfo.cost
    }, 0);



    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderRow = (flight) => {
        const weight = flight.mtow.MAX_TOW_KG;
        // TODO: change date_of_flight to be more accuracte
        const dateOfFlight = DateTime.fromISO(flight.etd);
        
        const formatDateFromISO = (date) => {
            const stringDateSplit = date.split('T')[0].split('-');
            return `${stringDateSplit[2]}/${stringDateSplit[1]}/${stringDateSplit[0]}`
        };

     
        const totalDist = flight.totalFirCalculatedInfo.distance_nm;
        const totalCost = flight.totalFirCalculatedInfo.cost;
        
       const handleTrackIcon =(event)=> {
        const flight_ref = event.target.value;
        axios.get(`/api/betaInvoice/track/${flight_ref}`)
                .then((res) => {
                  let flightInfo = res.data;
                    const atcCost = Number(flightInfo[0].firCalculatedInfo.map(fc => fc.commonUsdCost).reduce((acc, curr) => acc + curr));
                     const totalCost = atcCost;
                    
                    // return { ...flightInfo, atcCost, totalCosts }
            
                flightInfo = { ...flightInfo, atcCost, totalCost}
            
                //  const derivedInfo = getFlightInfoPlusDerived(flightInfo);
                //  console.log(derivedInfo[0])
                         const { flight_ref, mtow, departure, arrival, firCalculatedInfo, etd, eta, flightNumber, aircraftModel, legacy_format } = flightInfo[0];
                         const atcCosts = flightInfo.atcCost;
                           const totalCosts = totalCost;
                        setSelectedDate(DateTime.fromISO(flightInfo[0].etd))
                        // console.log(selectedDate)
        const selectedInfo = { atcCosts, totalCosts,flight_ref, mtow, departure, arrival, firCalculatedInfo, etd, eta, flightNumber, aircraftModel, legacy_format };
       
        // console.log(derivedInfo[0].flight_ref)
        axios.get(`/api/betainvoice/geometry/${flightInfo[0].flight_ref}`)
            .then((res) => {
                 const firRegionsPointData = res.data;
              
              let getWayPoints = [];
                axios.get(`/api/flightGains/waypoints/${flightInfo[0].flight_ref}`)
                .then((res)=>{
                     getWayPoints = res.data;
                
                     const firInfoWithPoints = firCalculatedInfo.map((f, i) => ({...f, 
                        geometry: firRegionsPointData[i],wayPointsC:getWayPoints}));
                 
                     const gcData = { type: "FeatureCollection", "features": firInfoWithPoints };
                
                     setSelectedFlightInfo(({ gcData, ...selectedInfo }));
        //              setSelectedDate(DateTime.fromISO(selectedFlightInfo.gcData.etd))
        //  console.log(selectedDate)
       
                     setMapModalOpen(true);
                }).catch(err => {
                    console.log(err);
                });
                
            })
            .catch(err => {
                console.log(err);
            });
                }); 
       }
    //    console.log(flight_ref)
      
        // const iconColor = colors.forEach(color=><ExploreTwoToneIcon style={{color:}}/>)
        return (
            <StyledTableRow key={flight.flight_ref} className={classes.row}>
                <StyledTableCell align="center"><IconButton variant="outlined" onClick={handleTrackIcon} value={flight.flight_ref}>{<ExploreIcon style={{color:'#bd0026'}}/>}</IconButton>{DateTime.fromISO(flight.etd).toLocaleString(DateTime.DATE_MED)}</StyledTableCell>
                <StyledTableCell align="center">{"FLX"+flight.flightNumber.slice(3)}</StyledTableCell>
                <StyledTableCell align="center">{flight.departure}</StyledTableCell>
                <StyledTableCell align="center">{flight.arrival}</StyledTableCell>
                <StyledTableCell align="center">{flight.aircraftModel}</StyledTableCell>
                <StyledTableCell align="center"><NumberFormat value={weight} displayType={'text'} thousandSeparator={true} /></StyledTableCell>
                <StyledTableCell align="center">{totalDist.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="center"><NumberFormat value={totalCost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
            </StyledTableRow>
        );
    }



    return (
        <TableContainer component={Box} elevation={1} className={classes.tableContainer}>
          {mapModalOpen && <FlightMapModal GreatCircleRef={GreatCircleRef}  setModalOpen={setMapModalOpen} selectedFlightInfo={selectedFlightInfo} selectedDate={selectedDate}/>}
            <Table size="small" aria-label="a dense table" ref={ref} >
                <StyledBlackTableHeader>
                    <TableRow>
                        <TableCell align="center" rowSpan={2} colSpan={1} className={classes.highlightTableHeader}><strong>{country}</strong></TableCell>
                        <TableCell align="center" rowSpan={2} className={classes.highlightTableHeader}> {date}</TableCell>
                        <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={numFlights} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell align="center" className={classes.highlightTableHeader}>{currency}</TableCell>
                        <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={charge} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell align="center" rowSpan={2}>Total Distance</TableCell>
                        <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={parseInt(totalDistance)} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell align="center" rowSpan={2}></TableCell>
                        {/* <TableCell align="center" className={classes.highlightTableHeader}>{getHoursMinutesSecondsString(totalTime)}</TableCell> */}
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">Flights</TableCell>
                        <TableCell align="center">Currency</TableCell>
                        <TableCell align="center">Charge</TableCell>
                        <TableCell align="center">N.M.</TableCell>
                        {/* <TableCell align="center">HH:MM:SS</TableCell> */}
                    </TableRow>
                </StyledBlackTableHeader>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                    <TableCell align="center">DATE</  TableCell>
                        <TableCell align="center">FLIGHT</TableCell>
                        {/* <TableCell align="center">Flight Number</TableCell> */}
                        <TableCell align="center">ORIGIN</TableCell>
                        <TableCell align="center">DESTINATION</TableCell>
                        <TableCell align="center">AIRCRAFT</TableCell>
                       
                        <TableCell align="center">WEIGHT (kg)</TableCell>
                        <TableCell align="center">DISTANCE (nm)</TableCell>
                        {/* <TableCell align="center">SEG TIME (HH:MM:SS)</TableCell> */}
                        <TableCell align="center">CHARGE</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : data
                    ).map((row, index) => renderRow(row))
                    }
                    {emptyRows > 0 && <TableRow>
                        <TableCell colSpan={6}></TableCell>
                        <TableCell align="center" className={classes.currencyCell}>{currency}</TableCell>
                        <TableCell align="center" className={classes.amountCell}><NumberFormat value={charge} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} /></TableCell>
                    </TableRow>}
                    {emptyRows - 1 > 0 && (
                        <TableRow style={{ height: 30 * emptyRows - 1 }}>
                            <TableCell colSpan={10} />
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            colSpan={10}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
});


export default UpdatedPsuedoInvoice;