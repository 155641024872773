import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { red } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '.MuiDataGrid-colCell': {
            color: 'red',
            backgroundColor: 'blue',
        }
    },
    container: {
        maxHeight: "60vh",
    
    },
    headerStyle: {
        color: red,
    }
});

const styles = theme => ({
    root: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'green',
      },
    },
  });
 
// TODO: clean this up, used in FlightInfoTable as well
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
    },
    body: {
        fontSize: '1rem',
    },

}))(TableCell);

const StyledMiniTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'darkgrey',
        fontWeight: 'bold',
        fontSize: 12,
    },
    body: {
        fontSize: '1rem',
    },
}))(TableCell);

const DetailedFirs = (props) => {
    const firRows = props.firs.map(f => {
        const { cost, country, waypoints, distance_nm, elapsed_time, flight_ref, long_name, tag, tag_order } = f;

        return ({
            tag_order,
            tag,
            cost,
            long_name,
            distance_nm,
            waypoints: waypoints && waypoints.join('→'),
            elapsed_time: getHoursMinutes(elapsed_time),
        });
    });

    const headers = [
        { label: 'FIR TAG', align: 'center' },
        { label: 'FIR NAME', align: 'center' },
        { label: 'FIR COST', align: 'center' },
        // { label: 'SVC-KPI', align: 'center' },
        { label: 'DIST. (NM)', align: 'center' },
        { label: 'TIME (HH:MM)', align: 'center' },
        { label: 'WAYPOINTS', align: 'center' },
    ];

    return (
        <>
            <Table size="small" aria-label="fir breakdown">
                <TableHead>
                    <TableRow>
                        {
                            headers.map(h => <StyledMiniTableCell key={h.label} align={h.align}>
                                {h.label}
                            </StyledMiniTableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {firRows.map(f =>
                        <TableRow key={f.tag_order}>
                            <StyledMiniTableCell align="center">{f.tag}</StyledMiniTableCell>
                            <StyledMiniTableCell align="center">{f.long_name}</StyledMiniTableCell>
                            <StyledMiniTableCell align="center">$ {f.cost.toFixed(2)}</StyledMiniTableCell>
                            {/* <StyledMiniTableCell align="center">{'N/A'}</StyledMiniTableCell> */}
                            <StyledMiniTableCell align="center">{f.distance_nm.toFixed(2)}</StyledMiniTableCell>
                            <StyledMiniTableCell align="center">{f.elapsed_time}</StyledMiniTableCell>
                            <StyledMiniTableCell align="center"style={{textTransform: "capitalize"}}>{f.waypoints}</StyledMiniTableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </>
    )
};

const DetailedCountryRow = (props) => {
    const classes = props.classes;
    const [countryName, firs] = props.firs;
    const [open, setOpen] = useState(false);
   
    const nmTravelled = firs.map(f => f.distance_nm).reduce((prev, curr) => (prev + curr), 0);
    const currency = firs[0].currency;
    const cost = firs.map(f => f.cost).reduce((prev, curr) => (prev + curr), 0);
    const totalTimeInFirs = firs.map(f => f.elapsed_time).reduce((prev, curr) => (prev + curr), 0);


    return (
        <>
            <TableRow className={classes.root} key={countryName}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center" component="th" scope="row">{countryName}</TableCell>
                <TableCell align="center">$&nbsp;{cost.toFixed(2)}</TableCell>
                <TableCell align="center">{currency}</TableCell>
                <TableCell align="center">{nmTravelled.toFixed(2)}</TableCell>
                <TableCell align="center">{getHoursMinutes(totalTimeInFirs)}</TableCell>
                <TableCell align="center">{'N/A'}</TableCell>
            </TableRow>
            <TableRow key={countryName + 'Details'}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.numCols}>
                    <Collapse disableStrictModeCompat={true} in={open} timeout="auto" unmountOnExit>
                        <Box margin={5}>
                            <DetailedFirs classes={classes} firs={firs} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
};

// TODO: Airline image icons
export default function DetailedFlightInfoTable(props) {
    const { firCalculatedInfo, flightRef } = props.detailedFirInfo;
    const classes = useStyles();
    const firCountries = Object.entries(firCalculatedInfo.reduce((countryFirCollection, currFir) => {
        const firCountry = currFir.country;
        if (!countryFirCollection[firCountry]) {
            countryFirCollection[firCountry] = []
        }
        countryFirCollection[firCountry].push(currFir);
        return countryFirCollection;
    }, {}));

    const headers = [
        { label: 'STATE', align: 'center' },
        { label: 'COST', align: 'center' },
        { label: 'CURRENCY', align: 'center' },
        { label: 'DIST. (NM)', align: 'center' },
        { label: 'ELAPSED TIME (HH:MM)', align: 'center' },
        { label: 'BURN (kgs/lbs)', align: 'center' },
    ];

    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        {
                            headers.map(h => <StyledTableCell key={h.label} align={h.align}>{h.label}</StyledTableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {firCountries.map((countryFirs, index) => (
                        // + 1 for empty cell with arrow in rows beneath
                        <DetailedCountryRow classes={classes} numCols={headers.length + 1} key={`${flightRef} + ${index}`} firs={countryFirs} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const getHoursMinutes = (seconds) => (new Date(seconds * 1000).toISOString().substr(11, 5));