import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#FFFAFA',
    border:'0.1em solid #42647F',
    position:'absolute',
    borderRadius: '30px',
    boxShadow: '8px 8px grey',
    left:'10%',
    marginTop:'80px'
    // borderRight: '0.1em solid black'
  },
  
  // tableRightBorder :{

  //  display: 'inline-block',
  // width: '2px',
  // backgroundColor: 'black',
  // margin: '0 10px',
  // height: '1em'
  // }
}));

export default function ListDividers() {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.root} aria-label="mailbox folders">
        {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
        <ListItem divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Name:</strong> ABCDEF</Typography>}  />
      </ListItem>
      <ListItem style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText style={{color:'#325C74'}} primary={<Typography style={{fontSize:'1rem'}}><strong>Address:</strong> </Typography>}/>
      </ListItem>
      <Divider />
      <ListItem divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Telephone:</strong> (876)960-3948,960-3965</Typography>}  />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>Fax:</strong> (876)920-0194</Typography>} />
      </ListItem>
      <ListItem  divider style={{paddingTop:'6px',paddingBottom:'6px'}}>
        <ListItemText  primary={<Typography style={{fontSize:'1rem',color:'#325C74'}}><strong>info@jcaa.gov.jm</strong> </Typography>} />
      </ListItem>
      
    </List>
   
  );
}
