import { Typography } from '@material-ui/core';
import { EventBusyRounded } from '@material-ui/icons';
import { render } from '@testing-library/react';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  PieChart,
  Pie,
  PolarRadiusAxis,
  ResponsiveContainer
} from "recharts";


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
  return (
    <div style={{ backgroundColor: '#fff' }}  className="custom-tooltip">
      <p style={{ padding: '1rem' }} className="label">{`${payload[0].payload.country} : $ ${payload[0].value.toFixed(2)}`}</p>
    </div>
  );
 
  };
  return null;
};


export default function CountryCostBarChart({ data }) {
  const handleCountryClick = (data) => {
    if (data) {
      // TODO: handle when a user clicks on a bar
    }
  };
  

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  
  return (
    <div >
    <Typography variant="h6" component="h3" align="center" style={{marginLeft:'40px'}} >
        FIR Cost Per Country (USD)
      </Typography>
      {/* <BarChart width={width} height={height} data={data} margin={{ top: 20, right: 80, bottom: 20, left: 20 }} onClick={handleCountryClick}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="country" labels={{ value: 'Country', position: 'bottom' }} />
        <YAxis label={{ value: '$ USD', angle: -90, position: 'insideLeft' }} />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="commonUsdCost" >
          {data.map((entry, index) => {
            return (
              <Cell key={`cell-${index}`} fill={entry.properties.stroke} />
            )
          })
          }
        </Bar>
      </BarChart>  */}
      <PieChart width={300} height={400} style={{marginLeft:'40px'}}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={true}
          label={renderCustomizedLabel}
          outerRadius={80}
          paddingAngle={1}
          fill="#8884d8"
          dataKey="commonUsdCost"
          name="country"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${entry.country}`} fill={entry.properties.stroke} label={entry.country} />)
          }
        </Pie>
        {/* <Legend content={renderLegend}/> */}
        <Tooltip  content={<CustomTooltip />}/>
      </PieChart>
     
      </div>
  )
};