import * as React from 'react';
import ExploreIcon from '@material-ui/icons/Explore';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, IconButton, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StyledTableRow from '../../../../../components/StyledTableRow/StyledTableRow';
import StyledTableCell from '../../../../../components/StyledTableCell/StyledTableCell';


const useStyles = makeStyles((theme) => ({
  tableContainer: {
    background: theme.palette.common.white,
    height:"420px",
    
  },
  tableHeader: {
    background: theme.palette.info.dark,
    "& th": {
      color: theme.palette.common.white,
      border: '1px solid #fff',
    },
  },
  row: {
    background: theme.palette.common.white
  },
  strokeCell: {
    padding: '0px'
  },
  currencyCell: {
    background: '#1D9EFF',
    color: '#fff'
  },
  amountCell: {
    background: '#0070C0',
    color: '#fff'
  }
}));

const UpdatedGreatCircleTable = React.forwardRef((props, ref) => {

  const classes = useStyles();
  let usdTotalAmount = 0;
  const commonCurrency = 'USD';
  const headers = props.headers;



  const createHeaders = (headers) => {
    return headers.map((h, index) => {
      return <TableCell key={index} {...h}>{h.value}</TableCell>
    });
  };

  const rows = props.features.map((row, index) => {
    usdTotalAmount += row.commonUsdCost;

    return (
      <StyledTableRow key={index} className={classes.row}>
        <StyledTableCell align="center" onClick={() => props.zoomToRoute(row)} style={{ cursor: 'pointer' }}>
          <IconButton className={classes.strokeCell} style={{ color: row.properties.stroke }} variant="outlined"><ExploreIcon /></IconButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.properties.country}
        </StyledTableCell>
        <StyledTableCell align="center">
          <NumberFormat
            value={row.properties.dist_km}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
          />
        </StyledTableCell>
        <StyledTableCell align="center">{row.properties.currency}</StyledTableCell>
        <StyledTableCell align="center">
          <NumberFormat
            value={row.properties.cost || "0"}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <NumberFormat
            value={row.commonUsdCost}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$ '}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  return (
    <TableContainer component={Box} elevation={1} className={classes.tableContainer}>
      <Table size="small" aria-label="a dense table" ref={ref} >
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {createHeaders(headers)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
          <TableRow>
            <TableCell colSpan={headers.length - 2}></TableCell>
            <TableCell align="center" className={classes.currencyCell}>{commonCurrency}</TableCell>
            <TableCell align="center" className={classes.amountCell}><NumberFormat value={usdTotalAmount} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$ '} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});


export default UpdatedGreatCircleTable;