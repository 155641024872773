import React from 'react';
import CurrentDateTime from './CurrentTimeDate';
import FlightDayPicker from './FlightDayPicker';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 1rem 1rem"
    },
});
export default function DateTimePanel({ selectedDate, handleDateChange, useAllDates, setUseAllDates }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
          
            <FlightDayPicker selectedDate={selectedDate} handleDateChange={handleDateChange} useAllDates={useAllDates} setUseAllDates={setUseAllDates} />
            {/* <CurrentDateTime /> */}
            </div>
    );
};