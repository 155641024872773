import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from "@material-ui/core";
import { getFormattedDateFromMonthYear } from '../../../shared/util';
import { DatePicker } from "@material-ui/pickers";
import axios from '../../../axios-usercharges';
import countries from "../../../countries.json"
import { Flight, FlightSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 0px',
        flexGrow: 1
    },
    leftPanel: {
        borderRight: "1px solid #ececec",
    },
    gridContainer: {
        minHeight: '100vh',
        width: '100%'
    },
    controlSection: {
        padding: "5px",
        minHeight: "110px",
        "& h2": {
            padding: "2px",
            fontWeight: 700,
            fontSize: "1rem",
        },
    },
    boldFont: {
        fontWeight: 'bold',
    },
    select: {
        fontWeight: 'bold',
        marginTop:'70px',
        fontSize:'1.5rem',
        color:'black'
    },
}));

export default function CountryDateSelection({ countriesByYearMonth, flightData, setSelectedData, getFlightData }) {
   
    const classes = useStyles();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedYearMonth, setSelectedYearMonth] = useState('');
    const [yearMonths, setYearMonths] = useState([]);

    const countriesAvailable = Object.keys(countriesByYearMonth);

    // const handleCountrySelect = (event) => {
    //     const country = event.target.value;
    //     setSelectedCountry(country);
    //     const year = Object.keys(countriesByYearMonth[country]);
    //     const months = countriesByYearMonth[country][year];
    //      const yearMonths = months.map(ym=>`${year[0]} ${ym}`)
    //     setYearMonths(yearMonths);
    //     setSelectedYearMonth('');
    //     setSelectedData('');
    //  }
    const handleCountrySelect = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        const year = Object.keys(countriesByYearMonth[country]).sort().reverse();
        const latestYear = year[0];
        const months = countriesByYearMonth[country][latestYear].slice(-5);
        const yearMonths = months.map(ym => `${latestYear} ${ym}`);
        setYearMonths(yearMonths);
        setSelectedYearMonth('');
        setSelectedData('');
    };
    
    
    // const handleCountrySelect = (event) => {
    //     const country = event.target.value;
    //     setSelectedCountry(country);
    //     const yearMonths = Object.keys(countriesByYearMonth[country]);
    //     setYearMonths(yearMonths);
    //     setSelectedYearMonth('');
    //     setSelectedData('');
    // };
    
    const handleYearMonthSelect = (event) => {
        const ym = event.target.value;
        setSelectedYearMonth(ym);  
    };

    const getCountryYearMonthsMenuItems = (country) => {
        // console.log(yearMonths)
        const menuItems = yearMonths.map(ym => {
            const formattedString = getFormattedDateFromMonthYear(ym);

            return <MenuItem key={ym} value={ym}>{formattedString}</MenuItem>
        });
        return (
            menuItems
        );
    };

     useEffect(() => {
        if(selectedCountry && selectedYearMonth){
            getFlightData(selectedCountry,selectedYearMonth);
           }

  },[selectedCountry,selectedYearMonth])



    return (
        <>
    
        <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop:'50px',display:'flex'}} className={classes.betaInvoice}>
       
            <Grid md={4} item className={classes.controlSection}>
                <Grid container>
                
                    <Grid xs item>
                   
                        <FormControl fullWidth variant="outlined">
                            <InputLabel shrink className={classes.select}>COUNTRY</InputLabel>
                            <Select value={selectedCountry} className={classes.boldFont} displayEmpty onChange={handleCountrySelect}>
                                {countriesAvailable &&
                                    countriesAvailable.sort().map((country) => (
                                        <MenuItem key={country} value={country}>{country}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                  
                    <Grid xs item>
                        <FormControl fullWidth variant="outlined" style={{marginLeft:'30px'}} > 
         
                            <InputLabel shrink className={classes.select}>DATE</InputLabel>
                            <Select value={selectedYearMonth} className={classes.boldFont} displayEmpty disabled={!selectedCountry} onChange={handleYearMonthSelect}>
                                {getCountryYearMonthsMenuItems(selectedCountry)}
                            </Select>
                         </FormControl>
                         
                    </Grid>
                </Grid>
               
            </Grid>
           
        </Box>
    
        </>
    );
};