import React, { useState, useEffect, createRef } from 'react'
import { TopBar } from "../topbar/Topbar";
import Navbar from '../navbar/Navbar'
import { Button,CircularProgress } from '@material-ui/core';
import FlightInfoTable from './FlightInfoTable';
import LuxonUtils from '@date-io/luxon';
import axios from '../../axios-usercharges';
import { DateTime } from 'luxon';
import DetailedFirModal from './DetailedFirModal';
import DateTimePanel from './DateTimePanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import FlightMapModal from './FlightMapModal';
import FlightInfoBanner from './FlightInfoBanner';
import { FormControl,Input,InputLabel } from '@material-ui/core';
import {
    TextField
  } from '@material-ui/core';
  import CachedIcon from '@material-ui/icons/Cached'
  import CurrentDateTime from './CurrentTimeDate';
  import ToggleButton from '@material-ui/lab/ToggleButton';
  import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
  import Grid from '@material-ui/core/Grid';


export default function FlightGains() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [flightsInfo, setFlightsInfo] = useState([]);
    const [detailedFirInfo, setDetailedFirInfo] = useState();
    const [firModalOpen, setFirModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(DateTime.utc());
    const [useAllDates, setUseAllDates] = useState(false);
    const [selectedFlightInfo, setSelectedFlightInfo] = useState(null);
    //  const [weightType ,setWeightType] = useState("LB")
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [selectedPointData, setSetSelectedPointData] = useState(null);
    const [wayPoints,setWayPoints] = useState([]);
  
    const GreatCircleRef = createRef();


    const handleVerifyButtonClick = ({ firCalculatedInfo, flightRef }) => {
        setDetailedFirInfo({ firCalculatedInfo, flightRef });
        setFirModalOpen(true);
    };

    const handleMapButtonClick = (flightInfo) => {
        const { flight_ref,atcCosts, totalCosts, mtow, departure, arrival, firCalculatedInfo, etd, eta, flightNumber, aircraftModel, legacy_format } = flightInfo;
        const selectedInfo = { flight_ref,atcCosts, totalCosts, mtow, departure, arrival, firCalculatedInfo, etd, eta, flightNumber, aircraftModel, legacy_format };
        axios.get(`/api/flightGains/geometry/${flightInfo.flight_ref}`)
            .then((res) => {
                const firRegionsPointData = res.data;
              let getWayPoints = [];
                axios.get(`/api/flightGains/waypoints/${flightInfo.flight_ref}`)
                .then((res)=>{
                     getWayPoints = res.data;
                     const firInfoWithPoints = firCalculatedInfo.map((f, i) => ({...f, geometry: firRegionsPointData[i],wayPointsC:getWayPoints}));
                 
                     const gcData = { type: "FeatureCollection", "features": firInfoWithPoints };
                    
                     setSelectedFlightInfo(({ gcData, ...selectedInfo }));
                  
                     setMapModalOpen(true);
                }).catch(err => {
                    console.log(err);
                });
                
            })
            .catch(err => {
                console.log(err);
            });
    };
    
    useEffect(() => {
        // TODO: generalize based on who's logged in
        axios.get(`/api/flightGains/JBU/${selectedDate}`)
            .then((res) => {
                setFlightsInfo(res.data);
                setIsLoaded(true);
            })
            .catch(err => {
                console.log(err);
            });

    }, [selectedDate]);
    
    const handleDateChange = (date) => {
        setUseAllDates(false);
        setSelectedDate(date);
    };

    const testClickHandler = () => {
        axios.get('/api/betaInvoice')
            .then((res) => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        // setIsLoaded(!isLoaded);
    };
    const testClickHandler2 = (month, year) => {
        axios.get(`/api/betaInvoice/${month}/${year}`)
            .then((res) => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        // setIsLoaded(!isLoaded);
    };
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState([]);
  
   const handleChange = event => {
      setSearchTerm(event.target.value);
    };
   React.useEffect(() => {
      const results = flightsInfo.filter(flight=>{
    //   console.log(person.flightNumber)
    return flight.flightNumber.includes(searchTerm)
      
      }
      
      );
      setSearchResults(results);
    }, [searchTerm]);
  
   const reset = () =>{
       setSearchResults([]);
       setSearchTerm("")
   }
//    const toggleButton = ()=> {
//     return ( )
//    }

  
    return (
        <>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <>
        
                    <Navbar index={2} /><div style={{display:"flex",flexDirection:'row'}}>
                    <DateTimePanel selectedDate={selectedDate} handleDateChange={handleDateChange} useAllDates={useAllDates} setUseAllDates={setUseAllDates} />
                  
                    <TextField style={{marginLeft:"70%"}}
              variant="outlined"
              margin="normal"
              required
              autoFocus
              placeholder="Search"
              value={searchTerm} 
              onChange={handleChange}
            />
            <CachedIcon  style={{marginTop:"30px",marginLeft:"10px"}} onClick={reset}/>
            {/* <Grid item style={{marginTop:'20px',marginLeft:'10px'}}>
            <ToggleButtonGroup size="small" value={weightType} exclusive onChange={()=>weightType === 'LB'? setWeightType('KG'):setWeightType('LB')}>
    <ToggleButton style={{width:"30px",height:"30px",color:"black",backgroundColor:"#C0C0C0"}}>
     <p fontSize="small">LB</p>
    </ToggleButton>
    <ToggleButton style={{width:"30px",height:"30px",color:"black",backgroundColor:"#C0C0C0"}} >
     <p fontSize="small">KG</p>
    </ToggleButton>
    </ToggleButtonGroup>
    </Grid> */}
            </div>
                    {/* <TexrFeild margin='dense' style={{marginLeft:'75%'}} value={searchTerm} onChange={handleChange}/></div> */}
                  
                    {/* <Button onClick={testClickHandler} variant="contained" color="primary">Test</Button> */}
                    {/* <Button onClick={() => testClickHandler2(2, 2021)} variant="contained" color="primary">Test</Button> */}
                    {/* {!isLoaded ? <div><CircularProgress size={70}
        left={-20}
        top={10}
        style={{marginLeft: '50%'}} /> <span style={{fontSize:"2rem"}}>Loading</span></div>: <FlightInfoTable handleMap={handleMapButtonClick} handleVerify={handleVerifyButtonClick} flightInfo={flightsInfo} selectedDate={selectedDate} useAllDates={useAllDates} />} */}
        {searchResults.length !== 0?<FlightInfoTable handleMap={handleMapButtonClick} handleVerify={handleVerifyButtonClick} flightInfo={searchResults} selectedDate={selectedDate} useAllDates={useAllDates}  />:<FlightInfoTable handleMap={handleMapButtonClick} handleVerify={handleVerifyButtonClick} flightInfo={flightsInfo} selectedDate={selectedDate} useAllDates={useAllDates}  />
           
        }
                    {firModalOpen && <DetailedFirModal firInfo={detailedFirInfo} setModalOpen={setFirModalOpen}></DetailedFirModal>}
                    {mapModalOpen && <FlightMapModal GreatCircleRef={GreatCircleRef} selectedDate={selectedDate} setModalOpen={setMapModalOpen} selectedFlightInfo={selectedFlightInfo} />}
                </>
            </MuiPickersUtilsProvider>
        </>
    );
};