// @flow
import React, { useRef, useImperativeHandle, useState } from "react";

import Map from "./Map/Map";
import useResize from "../../../../hooks/useResize";
import UpdatedGreatCircleTable from "./GreatCircleTable/UpdatedGreatCircleTable";
import { generatePDF } from "../../../../shared/pdf";
import { exportCSVFile } from "../../../../shared/csv";
import { makeStyles } from '@material-ui/core/styles';
import FlightInfoBanner from "../../../../components/FlightGains/FlightInfoBanner";
import CountryCostBarChart from "../../../../components/FlightGains/CountryCostBarChart";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import { Typography } from '@material-ui/core';
import DetailedFirModal from '../../../../components/FlightGains/DetailedFirModal';
import { DateTime } from 'luxon';



const useStyles = makeStyles({
  root: {
    // margin: '5px',
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: 'center',
  },
  colCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center'",
    margin: "2rem",
  }
})

const UpdatedGreatCircle = (props, ref) => {
  const componentRef = useRef();
  const hiddenMapRef = useRef();
  const mapRef = useRef();
  const tableRef = useRef();
  // const { width } = useResize(componentRef);

const wayPointsCoordinates = props.selectedFlightInfo.gcData.features[0].wayPointsC;
  const headers = {
    // 'properties.fir': 'FIR Code',
    // 'properties.fir_name': 'FIR Name',
    'country': 'FIR Country',
    'dist_km': 'SEG DIST',
    'currency': 'Currency',
    'cost': 'Charge',
    'commonUsdCost': 'Converted USD',
  };

  const newHeaders = [
    { id: 'explore', value: '', 'align': 'center', style: ({ width: '20px' }) },
    // { id: 'firCode', value: 'FIR Code', 'align': 'center', },
    // { id: 'firName', value: 'FIR Name', 'align': 'center', },
    { id: 'firCountry', value: 'FIR Country', 'align': 'center', },
    { id: 'distKm', value: 'SEG DIST (km)', 'align': 'center', },
    // { id: 'elapsed_time', value: 'Elapsed Time', 'align': 'right', },
    { id: 'currency', value: 'Billing Currency', 'align': 'center', },
    { id: 'amount', value: 'Invoiced Amount', 'align': 'center', },
    { id: 'usdAmount', value: 'Converted USD', 'align': 'center', },
  ];

  const zoomToRoute = (row) => {
    mapRef.current.fitBounds(row);
  }

  const generateGCPDF = () => {
    const gcData = props.selectedFlightInfo ? props.selectedFlightInfo.gcData.features : null;
    if (!gcData) return;
    const countryReducedGcData = Array.from(Object.values(countryReducedFeatures(gcData)).map(e => ({ ...e.properties, commonUsdCost: e.commonUsdCost })));
    const totalAmount = gcData.reduce((acc, curr) => acc + curr.commonUsdCost, 0);
    const data = gcData.map(gc => ({ ...gc.properties, commonUsdCost: gc.commonUsdCost.toFixed(2) }));
    // return;
    // const data = gcData.features.map((gc) => {
    //   totalAmount += +gc.properties.cost;
    //   return { ...gc.properties, currency: 'USD' }
    // });
    generatePDF(
      tableRef.current,
      'OVERFLIGHT CHARGES',
      countryReducedGcData,
      ['stroke', 'country', 'dist_km', 'currency', 'cost', 'commonUsdCost'],
      'USD',
      totalAmount.toFixed(2),
      'OVERFLIGHT CHARGES.pdf',
      hiddenMapRef.current.addMapToPDF,
      {
        'cost': (value) => Number(value).toFixed(2),
        commonUsdCost: (value) => Number(value).toFixed(2),
        dist_km: (value) => Number(value).toFixed(2),
      },
    );
  }

  useImperativeHandle(ref, () => ({
    generateGreatCiclePDF() {
      generateGCPDF();
    },
    generateGCExel() {
      const gcData = props.selectedFlightInfo ? props.selectedFlightInfo.gcData.features : null;
      if (!gcData) return;
      const countryReducedGcData = Array.from(Object.values(countryReducedFeatures(gcData)).map(e => {
        return ({ ...e.properties, commonUsdCost: Number(e.commonUsdCost).toFixed(2), cost: Number(e.properties.cost).toFixed(2) })
      }));

      const fileTitle = 'Overflight Charges';
      exportCSVFile(headers, countryReducedGcData, fileTitle);
    }
  }));

  const countryReducedFeatures = (data) => data.reduce((acc, curr) => {
    
    const { commonUsdCost, properties, country, geometry,elapsed_time, allPoints,wayPointsC } = curr;
    //  console.log(properties)
    if (!acc[country]) {
      acc[country] = { country, commonUsdCost, properties, geometry,elapsed_time, type: "Feature" };
    } else {
      const accData = acc[country];
      const accP = accData.properties;
      const tmpProperties = {
        ...accData.properties,
        cost: +accP.cost + +properties.cost,
        dist_km: +accP.dist_km + +properties.dist_km,
        elapsed_time:+elapsed_time
      };

      const accG = accData.geometry;
      const tmpGeometry = {
        ...accG,
        coordinates: accG.coordinates.concat(geometry.coordinates),
      };
      acc[country] = {
        ...acc[country],
        commonUsdCost: +accData.commonUsdCost + +commonUsdCost,
        properties: tmpProperties,
        geometry: tmpGeometry,
       
      };
    }
    return acc;
  }, {});

  let content = "";
  const classes = useStyles();
  const width = 750;//1920 / 3;
 
  if (props.selectedFlightInfo && props.selectedFlightInfo.gcData) {
    const { gcData } = props.selectedFlightInfo;

    const countryReducedGcData = Array.from(Object.values(countryReducedFeatures(gcData.features)));
    // console.log(countryReducedGcData)
    const featureCollection = { type: "FeatureCollection", features: countryReducedGcData };

    const flight = countryReducedGcData.map(data=>{
      return data.properties
     })

     const flight1 = countryReducedGcData.map(data=>{
      return data.properties = {...data.properties,elapsed_time:data.elapsed_time}
     })
     
     const chargePerKm = (flight)=>{
       return (+flight.cost/+flight.dist_km).toFixed(2)
     }
     const costPerMin = (flight1) => {
      if (flight1.elapsed_time) {
        const time = flight1.elapsed_time
        return (+flight1.cost / (+time)).toFixed(2);
      } else {
        return 0;
      }
    };
    content = (
      <>
        <div style={{display:"flex",flexDirection:"column"}}>
            <FlightInfoBanner selectedFlightInfo={props.selectedFlightInfo} GreatCircleRef={ref} />
      
         
        <div style={{display:"flex",flexDirection:"row",marginTop:"50px",justifyContent:"space-evenly"}}>
            <Map geojson={featureCollection} width={width} height={500} ref={mapRef} style={{border:"solid black"}} wayPointsC={wayPointsCoordinates} selectedDate={props.selectedDate.c} flightNumber={"FLX"+props.selectedFlightInfo.flightNumber.slice(3)}/>
            <Map geojson={featureCollection} width={1200} height={600} ref={hiddenMapRef} hide={true} wayPointsC={wayPointsCoordinates} selectedDate={props.selectedDate.c} flightNumber={"FLX"+props.selectedFlightInfo.flightNumber.slice(3)}/>
           <div style={{right:"40px"}}>
              <UpdatedGreatCircleTable features={countryReducedGcData} zoomToRoute={zoomToRoute} headers={newHeaders} ref={tableRef} GreatCircleRef={ref} />
              </div>
              </div>
              <div style={{display:"flex",flexDirection:"row"}}>
              <CountryCostBarChart data={countryReducedGcData} />
              <Typography variant="h6" component="h3" align="center" style={{marginLeft:"15%"}} >
        Charge Per KM
      </Typography>
              <BarChart width={400} height={350} data={flight} style={{position:"absolute",marginLeft:"25%",marginTop:"50px"}}>
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="country" interval={0} angle={-45} textAnchor="end" height={100} />
            <YAxis type="number" interval={0} tickFormatter={(tick) => '$' + tick}  allowDecimals={true} />
        
            <Tooltip />
            <Bar dataKey={chargePerKm} fill="#4E81BD" />
            </BarChart>
            <Typography variant="h6" component="h3" align="center" style={{marginLeft:'30%'}}>
        Charge Per Min
      </Typography>
            <BarChart width={400} height={350} data={flight1} style={{position:"absolute",marginLeft:"60%",marginTop:"50px"}}>
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="country" interval={0} angle={-45} textAnchor="end" height={100} />
            <YAxis type="number" interval={0} tickFormatter={(tick) => '$' + tick}  allowDecimals={true} />
        
            <Tooltip />
            <Bar dataKey={costPerMin} fill="#9DBD5B" />
            </BarChart>
            </div>
            </div>
       
      </>
    );
  }
  return <div ref={componentRef}>{content}</div>;
};

export default React.forwardRef(UpdatedGreatCircle);
